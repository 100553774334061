

function ImageBanner(props) {
     
    return (
        <div className="ImageContainer">
            
            <div className="ImageBanner">{props.title}</div>
            
        </div>
    )
}

export default ImageBanner
